import './utils/serverSide/shims';
import './window';
import './polyfills';
import './utils/pageTransitions';
import ReactDOM from 'react-dom';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { importComponent } from './utils/importComponent';
// Expose all components for React.NET (SSR)
globalThis.React = React;
globalThis.ReactDOM = ReactDOM;
globalThis.ReactDOMServer = ReactDOMServer;
globalThis.Components = {
    OverviewSearchLazyLoad: importComponent('Overview'),
    ActionBar: importComponent('ActionBar'),
    Search: importComponent('Search')
};
